export default {
    Auth: {
        identityPoolId: 'us-east-1:ae7090b0-150d-4919-be8f-cb1c3c22725f',
        region: 'us-east-1',
        userPoolId: 'us-east-1_w6m79m0KF',
        userPoolWebClientId: '4d248fkk4rnlqgmvv1j37lnjhd',
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    API: {
        aws_appsync_graphqlEndpoint:
            'https://ae5exyygqzeargpgmqezlqo3hm.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
    },
    Storage: {
        AWSS3: {
            bucket: 'rhenium-prod-storage-graphql',
            region: 'us-east-1'
        }
    }
}
