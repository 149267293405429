export default {
    VOPAY_EMBEDURL: 'https://embed.vopay.com/d4189ca832cece57b92565609ec784dfdf31ad32',
    GOOGLE_MAP_API_KEY: 'AIzaSyAS_UbROz2oe4ycGzqB5MJzN_WFhvwCwYs',
    GOOGLE_PLACES_API_KEY: 'AIzaSyAS_UbROz2oe4ycGzqB5MJzN_WFhvwCwYs',
    GTM_DEV: {
       GTM_ACCOUNT:'GTM-WD6R9WX',
       GTM_AUTH:'dfjq0hKcDHt6q6bj-Yg3IA',
       GTM_PREVIEW:'env-3',
    },
    GTM_STAGING: {
       GTM_ACCOUNT:'GTM-WD6R9WX',
       GTM_AUTH:'ge_sk-tZIqs_BGFn7-lNtA',
       GTM_PREVIEW:'env-8',
    },
    GTM_PROD: {
       GTM_ACCOUNT:'GTM-MPPFSVW',
       GTM_AUTH:'YIM6yEbzPukO-coyj5x8lw',
       GTM_PREVIEW:'env-1',
    },
    ZENTINEL_URL: 'https://webagent.zentinel.app/',
    ZENTINEL_API_URL: 'https://zentinel.xchange.loans',
    SHOW_BUYER_REQUEST_BUTTON: 'FALSE',
    SHOW_ASSET_REPORTS:'FALSE',
    SHOW_SELLER_PROFILE: 'TRUE',
    STRIPE_PUBLISHABLE_KEY:'pk_live_6wPh48yjymQVUUb5kgS5QwyL00gnwhAuwW',
    SENTRY_DSN:'https://7cb8ee6ebf344b1489e07647892a83fe@o4503932588392448.ingest.sentry.io/4503998469242880',
    SENTRY_ENVIRONMENT:'prod',
    ZENTINEL_CLIENT_ID:'xchangeloans-production',
    ZENTINEL_CATEGORY_ID:'31480',
    ZENTINEL_SITE_URL:'https://zentinel.app'
}
